/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import {
  isAbsoluteUrl, isValidArray, isValidString, getKey,
} from '../../../../../helpers';
import Arrow from '../Arrow';
import Link from '../../../../base/Link';
import SubSection from '../SubSection';
import Animation from './Items.animation';
import Styles from './Items.styles';

const Button = styled(motion.button)`${Styles.button}`; // prettier-ignore
const LinkComponent = styled(
  ({
    isFirst,
    isLast,
    isSection,
    ...otherProps
  }) => <Link {...otherProps} />
)`${Styles.linkComponent}`; // prettier-ignore
const Row = styled(motion.div)`${Styles.row}`; // prettier-ignore
const Section = styled(motion.div)`${Styles.section}`; // prettier-ignore

/**
 * Items component
 * @param {Object} props - component props
 * @param {function} props.addSectionOpen - add sections to the store function
 * @param {Array} props.menu - list of sections
 * @param {function} props.removeSectionOpen - remove sections to the store function
 * @param {function} props.sectionsOpen - store value with open sections
 * @returns {JSX}
 */
const Items = ({
  addSectionOpen, menu, removeSectionOpen, sectionsOpen
}) => {
  const handleClick = useCallback(
    (index, hasIndex) => {
      if (!hasIndex) {
        addSectionOpen(index);
      } else {
        removeSectionOpen(index);
      }
    },
    [addSectionOpen, removeSectionOpen]
  );

  const lastSection = menu.length - 1;

  return menu.map(({
    childs,
    externalLink,
    id,
    name,
    page: {
      uri,
    },
    target = '_self',
  }, index) => {
    const isLastSection = index === lastSection;
    const hasExternalLink = isValidString(externalLink);
    const link = hasExternalLink ? externalLink : uri;

    if (isValidArray(childs)) {
      const hasIndex = sectionsOpen.includes(index);

      return (
        <Section
          key={id}
          variants={Animation}
          custom="section"
          isLastSection={isLastSection}
          initial={false}
        >
          <Row itemProp="name">
            <LinkComponent
              href={link}
              itemProp="url"
              isSection
              preloadDisabled={isAbsoluteUrl(link)}
              target={target}
            >
              {name}
            </LinkComponent>
            <Button onClick={event => handleClick(index, hasIndex, event)}>
              <Arrow isOpen={hasIndex} />
            </Button>
          </Row>
          <SubSection isOpen={hasIndex}>
            {childs.map(({
              externalLink: subSectionExternalLink,
              id: subsectionId,
              name: subSectionName,
              page,
              target: subTarget = '_self',
            }, idx) => {
              const subSectionUri = getKey(page, 'uri');
              const hasSubExternalLink = isValidString(subSectionExternalLink);
              const subSectionLink = hasSubExternalLink ? subSectionExternalLink : subSectionUri;
              return (
                <li key={subsectionId} itemProp="name">
                  <LinkComponent
                    href={subSectionLink}
                    isFirst={idx === 0}
                    isLast={idx === childs.length - 1}
                    itemProp="url"
                    preloadDisabled={isAbsoluteUrl(subSectionLink)}
                    target={subTarget}
                  >
                    {subSectionName}
                  </LinkComponent>
                </li>
              );
            })}
          </SubSection>
        </Section>
      );
    }

    return (
      <Section
        key={id}
        variants={Animation}
        custom="section"
        isLastSection={isLastSection}
        itemProp="name"
        initial={false}
      >
        <LinkComponent
          href={link}
          itemProp="url"
          isSection
        >
          {name}
        </LinkComponent>
      </Section>
    );
  });
};

Items.propTypes = {
  addSectionOpen: PropTypes.func,
  menu: PropTypes.array.isRequired,
  removeSectionOpen: PropTypes.func,
  sectionsOpen: PropTypes.array,
};

export default Items;
