import { css } from 'styled-components';

import { media } from '../../../helpers/mixins';
import { HEADER_INDEX } from '../../../constants/zIndex';
import { WOODSMOKE, WOODSMOKE_00 } from '../../../constants/colors';

export default {
  container: css`
    position: relative;
  `,
  logo: css`
    margin-left: 0;
    margin-top: 9px;
    position: absolute;
    z-index: 10;
  `,
  site: css`
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(0px 0px 99.9% 99.9%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 1px;
  `,
  wrapper: css`
    color: white;
    display: flex;
    height: 50px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${HEADER_INDEX};

    @media (max-width: 768px) {
      &:after {
        background: linear-gradient(to bottom, ${WOODSMOKE} 0%, ${WOODSMOKE_00} 100%);
        content: '';
        height: 100px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    ${media.md`
      backdrop-filter: blur(5px);
    `}
  `,
};
