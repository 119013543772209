import React, { memo } from 'react';
import styled from 'styled-components';

import AlestraLogo from '../../base/AlestraLogo';
import Link from '../../base/Link';
import HamburgerMenu from './HamburgerMenu';
import Styles from './Header.styles';

const Container = styled.div`${Styles.container}`; // prettier-ignore
const Site = styled.h1`${Styles.site}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

/**
 * Header Component
 * @returns {JSX}
 */
const Header = () => (
  <Wrapper className="Header_Wrapper">
    <Container className="container">
      <Link href="/" css={Styles.logo}>
        <AlestraLogo />
        <Site>Alestra</Site>
      </Link>
      <HamburgerMenu />
    </Container>
  </Wrapper>
);

export default memo(Header);
