export default {
  closed: element => {
    switch (element) {
      case 'section':
        return {
          opacity: 0,
          scale: 1,
          transition: {
            y: {
              stiffness: 1500,
              velocity: 100,
            }
          },
          transitionEnd: {
            scale: 1.2,
            y: 60,
          },
          y: 0,
        };
      default:
        return {};
    }
  },
  open: element => {
    switch (element) {
      case 'section':
        return {
          opacity: 1,
          scale: 1,
          transition: {
            y: {
              stiffness: 1000,
              velocity: -100,
            }
          },
          y: 0,
        };
      default:
        return {};
    }
  },
};
