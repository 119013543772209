export default {
  isClosed: {
    d: 'M 5 6.5 L 10 11.5 M 10 11.5 L 15 6.5',
    transition: {
      damping: 30,
      stiffness: 500,
      type: 'spring',
    },
  },
  isOpen: {
    d: 'M 5 11 L 10 6 M 10 6 L 15 11',
    transition: {
      damping: 30,
      stiffness: 500,
      type: 'spring',
    },
  },
};
