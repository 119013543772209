import React from 'react';
import PropTypes from 'prop-types';

import Layout from './src/components/Layout';

/**
 *  wrapPageElement
 * @param {Object} params - component params
 * @param {Array|element|function|Node} params.element - component element
 * @param {Object} params.props - component props
 * @returns {JSX}
 */
const wrapPageElement = ({ element }) => (
  <Layout>{element}</Layout>
);

wrapPageElement.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
};

export default wrapPageElement;
