import { css } from 'styled-components';

import { media } from '../../../../helpers/mixins';
import {
  WHITE,
  WHITE_00,
  MID_GRAY,
} from '../../../../constants/colors';

export default {
  button: css`
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 30px;
    outline: none;
    padding: 0;
    position: absolute;
    right: 12px;
    top: 7px;
    user-select: none;
    width: 30px;
    z-index: 7;
  `,
  circleAnimation: css`
    -webkit-clip-path: circle(1px at calc(100% - 27px) 26px);
    background-color: ${WHITE};
    clip-path: circle(1px at calc(100% - 27px) 26px);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  `,
  mask: css`
    height: 0vh;
    overflow: hidden;
    position: absolute;
    user-select: none;
    width: 100vw;
    z-index: 6;

    ${media.md`
      max-width: 300px;
    `}
  `,
  nav: ({ isHamburgerOpen }) => css`
    -webkit-overflow-scrolling: touch;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: touch;
    padding: 70px 0 140px 0;
    pointer-events: none;
    width: 100%;
    z-index: 2;

    ${media.md`
      padding-bottom: 0;
    `}

    ${isHamburgerOpen && css`
      pointer-events: unset;
    `}
  `,
  path: ({ isHamburgerOpen }) => css`
    fill: transparent;
    stroke: ${isHamburgerOpen ? MID_GRAY : WHITE};
    stroke-linecap: round;
    stroke-width: 2;
  `,
  scrollWrapper: ({ isVisible }) => css`
    background: linear-gradient(to bottom, ${WHITE} 71.4%, ${WHITE_00} 100%);
    height: 70px;
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 4;

    ${isVisible && css`
      visibility: visible;
    `}
  `,
  svg: css`
    margin-top: 4px;
    position: relative;
    z-index: 2;
  `,
  wrapper: css`
    min-width: 100%;
    position: absolute;
    right: 0;
    top: 0;

    ${media.md`
      min-width: 300px;
    `}
  `,
};
