import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Styles from './SubSection.styles';
import Animation from './SubSection.animation';

const SubSectionItem = styled(motion.ul)`${Styles.subSectionItem}`; // prettier-ignore

/**
 * SubSection Component
 * @param {props} props - component props
 * @param {Array|element|function|Node} props.children- component childs
 * @param {boolean} props.isOpen - if true, it will render the arrow down
 * @returns {JSX}
 */
const SubSection = ({ children, isOpen }) => (
  <SubSectionItem
    variants={Animation}
    animate={isOpen ? 'isOpen' : 'isClosed'}
    initial={false}
  >
    {children}
  </SubSectionItem>
);

SubSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
  isOpen: PropTypes.bool,
};

export default SubSection;
