import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import store from './src/store';

/**
 *  wrapRootElement
 * @param {Object} params - component params
 * @param {Array|element|function|Node} params.element - component element
 * @returns {JSX}
 */
const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
);

wrapRootElement.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
};

export default wrapRootElement;
