const hamburgerTransition = {
  transition: {
    damping: 25,
    stiffness: 200,
    type: 'spring',
  },
};

const hamburgerTransitionClosed = {
  transition: {
    damping: 25,
    stiffness: 100,
    type: 'spring',
  },
};

export default {
  closed: element => {
    switch (element) {
      case 'svg':
        return {
          rotate: '0deg',
          ...hamburgerTransitionClosed
        };
      case 'bar1':
        return {
          d: 'M 7 10 L 23 10',
          ...hamburgerTransitionClosed
        };
      case 'bar2':
        return {
          d: 'M 7 15 L 23 15',
          ...hamburgerTransitionClosed
        };
      case 'bar3':
        return {
          d: 'M 7 20 L 23 20',
          ...hamburgerTransitionClosed
        };
      default:
        return {};
    }
  },
  open: element => {
    switch (element) {
      case 'svg':
        return {
          rotate: '90deg',
          ...hamburgerTransition
        };
      case 'bar1':
        return {
          d: 'M 9 21 L 21 9',
          ...hamburgerTransition
        };
      case 'bar2':
        return {
          d: 'M 16 15 L 16 15',
          ...hamburgerTransition
        };
      case 'bar3':
        return {
          d: 'M 9 9 L 21 21',
          ...hamburgerTransition
        };
      default:
        return {};
    }
  },
};
