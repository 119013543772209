import { css } from 'styled-components';
import breakPoints from '../../constants/breakPoints';

/**
 * breakpoints helpers for styled components in web
 * @example ${media.md`background: black;` }
 */
export const media = Object.keys(breakPoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
      @media (min-width: ${breakPoints[label]}px) {
        ${css(...args)};
      }
    `;

  return acc;
}, {});

/**
 * Helper to convert px to rem units with styled components
 * @example font-size: ${rem(15)}; or font-size: ${rem('15px')};
 * @example font-size @{rem`15`};
 * @param {number|string} number - number in px to convert
 * @param {number} [base = 16] - base
 * @returns {string}
 */
export function rem(number, base = 16) {
  return `${parseFloat(number) / base}rem`;
}

/**
 * helper to truncate a text at certain width in web
 * @param {number} width - width limit
 * @returns {func}
 */
export function truncate(width) {
  return css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${width}px;
  `;
}

/**
 * helper to add an offset to columns
 * @param {number} columns - number of colument for offset
 * @returns {func}
 */
export function offsetCol(columns) {
  if (!columns) return null;

  return css`
    margin-left: ${(columns / 12) * 100}%;
  `;
}

/**
 * helper to add set the max columns
 * @param {number} columns - number of max columns
 * @returns {func}
 */
export function maxCol(columns) {
  if (!columns) return null;

  return css`
    max-width: ${(columns / 12) * 100}%;
    width: ${(columns / 12) * 100}%;
  `;
}

/**
 * builds css string for a horizontal gradient
 * @param {string} start color
 * @param {string} end color
 * @returns {string}
 */
export function horizontalGradient(start, end) {
  if (!start || !end) return null;

  return `linear-gradient(90deg, ${start} 0%, ${end} 100%);`;
}
