export default {
  closed: ({ element }) => {
    switch (element) {
      case 'circleAnimation':
        return {
          clipPath: 'circle(1px at calc(100% - 27px) 26px)',
          transition: {
            damping: 100,
            stiffness: 330,
            type: 'spring',
            velocity: -70,
          },
        };
      case 'mask':
        return {
          height: 0,
          transition: {
            damping: 40,
            stiffness: 10,
            type: 'spring',
            velocity: -100,
          },
        };
      case 'navigation':
        return {
        };
      default:
        return {};
    }
  },
  open: ({ element, heightValue }) => {
    switch (element) {
      case 'circleAnimation':
        return {
          clipPath: `circle(${heightValue + 200}px at calc(100% - 20px) 20px)`,
          transition: {
            damping: 20,
            restDelta: 40,
            stiffness: 100,
            type: 'spring',
          },
        };
      case 'navigation':
        return {
          height: '100vh',
          transition: {
            delayChildren: 0.1,
            staggerChildren: 0.03,
          },
        };
      case 'mask':
        return {
          height: heightValue,
          transition: {
            damping: 60,
            stiffness: 900,
            type: 'spring',
          },
        };
      default:
        return {};
    }
  },
};
