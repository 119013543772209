export const BLACK_RGB = '0,0,0';
export const WOODSMOKE_RGB = '18, 18, 22';
export const WHITE_RGB = '255, 255, 255';

// Standard Colors (http://chir.ag/projects/name-that-color for creating standardized color names)
export const ALABASTER = '#F7F7F7';
export const BLACK = '#000000';
export const BLACK_00 = `rgba(${BLACK_RGB}, 0)`;
export const CERULEAN = '#00B8FF';
export const DODGER_BLUE = '#00B8FF';
export const ELECTRIC_VIOLET = '#A314E2';
export const GRAY = '#828282';
export const MID_GRAY = '#63636D';
export const OUTRAGEOUS_ORANGE = '#FF5D32';
export const SCORPION = '#595959';
export const SHARK = '#222226';
export const SILVER = '#BDBDBD';
export const ULTRAMARINE = '#10069F';
export const WHITE = '#FFFFFF';
export const WHITE_00 = `rgba(${WHITE_RGB}, 0)`;
export const WHITE_40 = `rgba(${WHITE_RGB}, 0.4)`;
export const WOODSMOKE = '#121216';
export const WOODSMOKE_00 = `rgba(${WOODSMOKE_RGB}, 0)`;

// Gradients
export const GRADIENT_CERULEAN_TO_PURPLE = 'linear-gradient(90deg, rgba(0,167,225,1) 0%, rgba(89,0,188,1) 100%);';
export const GRADIENT_VIOLET_TO_BLUE = 'linear-gradient(90deg, rgba(134,0,255,1) 0%, rgba(26,111,250,1) 100%);';
export const GRADIENT_PURPLE_TO_BLUE = 'linear-gradient(0deg, rgba(134,0,255,1) 0%, rgba(26,111,250,1) 100%);';
export const GRADIENT_LIGHT_BLUE_TO_BLUE = 'linear-gradient(90deg, rgba(0, 188, 255, 1) 0%, rgba(26, 58, 250, 1) 100%);';
export const GRADIENT_ORANGE_TO_VIOLET = `linear-gradient(to right, ${OUTRAGEOUS_ORANGE}, ${ELECTRIC_VIOLET})`;
