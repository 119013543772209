import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  isAbsoluteUrl, isValidArray, isValidString, getKey,
} from '../../../../helpers';
import Link from '../../../base/Link';
import Styles from './FooterSection.styles';

const ContactInfo = styled.div`${Styles.linkComponent}`;
const LinkComponent = styled(
  ({
    isSection,
    ...otherProps
  }) => <Link {...otherProps} />
)`${Styles.linkComponent}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

/**
 * FooterNavigation
 * @param {Object} props - component props
 * @returns {JSX}
 */
const FooterNavigation = ({ navigation }) => {
  return navigation.map(({
    childs, externalLink, id, name, page, target = '_self', type,
  }) => {
    if (type === 'contact') {
      return (
        <Wrapper className="col-6 col-md-2c" key={type}>
          <ContactInfo isSection>Contrataciones</ContactInfo>
          <ContactInfo>800 2537872</ContactInfo>
          <ContactInfo isSection hasMargin>Quejas, sugerencias, servicio y soporte</ContactInfo>
          <ContactInfo>800 112 6222</ContactInfo>
          <ContactInfo isSection hasMargin>Whatsapp</ContactInfo>
          <ContactInfo>81 3040 0204</ContactInfo>
        </Wrapper>
      );
    }

    const uri = getKey(page, 'uri');
    const hasExternalLink = isValidString(externalLink);
    const link = hasExternalLink ? externalLink : uri;

    if (isValidArray(childs)) {
      return (
        <Wrapper className="col-6 col-md-2c" key={id}>
          <LinkComponent
            href={link}
            isSection
            preloadDisabled={isAbsoluteUrl(link)}
            target={target}
          >
            {name}
          </LinkComponent>
          {childs.map(({
            externalLink: subSectionExternalLink,
            id: subSectionId,
            name: subSectionName,
            page: subPage,
            target: subTarget = '_self',
          }) => {
            const subSectionUri = getKey(subPage, 'uri');
            const hasSubExternalLink = isValidString(subSectionExternalLink);
            const subLink = hasSubExternalLink ? subSectionExternalLink : subSectionUri;
            return (
              <LinkComponent
                href={subLink}
                key={subSectionId}
                preloadDisabled={isAbsoluteUrl(subLink)}
                target={subTarget}
              >
                {subSectionName}
              </LinkComponent>
            );
          })}
        </Wrapper>
      );
    }

    return (
      <Wrapper className="col-6 col-md-2c" key={id}>
        <LinkComponent
          key={link}
          href={link}
          isSection
          preloadDisabled={isAbsoluteUrl(link)}
          target={target}
        >
          {name}
        </LinkComponent>
      </Wrapper>
    );
  });
};

FooterNavigation.propTypes = {
  navigation: PropTypes.array,
};

export default FooterNavigation;
