// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-bit-and-bytes-js": () => import("./../../../src/templates/BitAndBytes.js" /* webpackChunkName: "component---src-templates-bit-and-bytes-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-buscador-js": () => import("./../../../src/templates/Buscador.js" /* webpackChunkName: "component---src-templates-buscador-js" */),
  "component---src-templates-deathmatch-js": () => import("./../../../src/templates/Deathmatch.js" /* webpackChunkName: "component---src-templates-deathmatch-js" */),
  "component---src-templates-event-page-js": () => import("./../../../src/templates/EventPage.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/Solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/Tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-techcast-js": () => import("./../../../src/templates/Techcast.js" /* webpackChunkName: "component---src-templates-techcast-js" */)
}

