import { connect } from 'react-redux';

import {
  hasSliderSelector,
  scrollAnimationEndSelector,
  scrollAnimationStartSelector,
  scrollAnimationStatusSelector
} from '../../store/selectors';
import { setSliderRef, removeSliderRef, setScrollAnimationStatus } from '../../store/actions';
import Layout from './Layout';

/**
 * map State to props
 * @param {Object} state - store state
 * @returns {Object}
 */
const mapStateToProps = state => ({
  hasSlider: hasSliderSelector(state),
  scrollAnimationEnd: scrollAnimationEndSelector(state),
  scrollAnimationStart: scrollAnimationStartSelector(state),
  scrollAnimationStatus: scrollAnimationStatusSelector(state),
});

const mapDispatchToProps = {
  removeSliderRef,
  setScrollAnimationStatus,
  setSliderRef,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
