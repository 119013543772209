import { css } from 'styled-components';

import { CERULEAN, MID_GRAY } from '../../../../constants/colors';
import { rem } from '../../../../helpers/mixins';

export default {
  linkComponent: ({ hasMargin, isSection }) => css`
    color: ${isSection ? CERULEAN : MID_GRAY};
    display: flex;
    font-size: ${rem('14px')};
    width: fit-content;

    ${isSection && css`
      font-weight: 700;
      margin-bottom: 8px;
      text-transform: uppercase;
    `}

    ${!isSection && css`
      margin-bottom: 6px;
    `}

    &&& {
      color: ${isSection ? CERULEAN : MID_GRAY};
    }

    ${hasMargin && css`
      margin-top: 16px;
    `}
  `,
  wrapper: css`
    margin-bottom: 32px;
  `,
};
