import { css } from 'styled-components';

import { CERULEAN, MID_GRAY } from '../../../../../constants/colors';

export default {
  button: css`
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 56px;
    outline: none;
    padding: 0;
    position: absolute;
    right: -7px;
    top: 0;
    user-select: none;
    width: 40px;
    z-index: 2;
  `,
  linkComponent: ({ isFirst, isLast, isSection }) => css`
    display: flex;
    padding: ${isSection ? '16px 8px' : '7px'};
    width: 100%;

    ${isSection && css`
      font-weight: 700;
      text-transform: uppercase;
    `}

    ${isFirst && css`
      padding-top: 0;
    `}

    ${isLast && css`
      padding-bottom: 18px;
    `}

    ${!isSection && css`
      padding-left: 16px;
    `}
  `,
  row: css`
    position: relative;
  `,
  section: ({ isLastSection }) => css`
    color: ${CERULEAN};
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: calc(100% - 30px);
    z-index: 3;

    ${!isLastSection && css`
      border-bottom: 1px solid ${MID_GRAY};
    `}
  `,
};
