import { createGlobalStyle } from 'styled-components';

import breakPoints from '../../../constants/breakPoints';
import { BLACK, BLACK_00, WHITE } from '../../../constants/colors';
import AvenirNextBoldWOFF from '../../../../static/fonts/AvenirNext-Bold.woff';
import AvenirNextBoldWOFF2 from '../../../../static/fonts/AvenirNext-Bold.woff2';
import AvenirNextMediumWOFF from '../../../../static/fonts/AvenirNext-Medium.woff';
import AvenirNextMediumWOFF2 from '../../../../static/fonts/AvenirNext-Medium.woff2';
import AvenirNextRegularWOFF from '../../../../static/fonts/AvenirNext-Regular.woff';
import AvenirNextRegularWOFF2 from '../../../../static/fonts/AvenirNext-Regular.woff2';

const PADDING = 24;

export default createGlobalStyle`
  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: ${BLACK_00};
  }

  ${'@-ms-viewport'} {
    width: device-width;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Avenir Next', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${BLACK};
    text-align: left;
    background-color: ${WHITE};
    width: 100%;
    height: 100%;

    @media (min-width: ${breakPoints.md}px) {
      overflow: hidden;
    }
  }

  article, aside, footer, header, hgroup, main, nav, section {
    display: block;
  }

  [tabindex="-1"]:focus {
    outline: 0 !important;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  a {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  a:hover {
    color: inherit;
    text-decoration: none;
  }

  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }

  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
  }

  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  table {
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
  }

  th {
    text-align: inherit;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  button {
    border-radius: 0;
  }

  input,
  button,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  .container {
    width: 100%;
    padding-right: ${PADDING}px;
    padding-left: ${PADDING}px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: ${breakPoints.md}px) {
  .container {
    max-width: ${breakPoints.md}px;
  }
}

  @media (min-width: ${breakPoints.lg}px) {
    .container {
      max-width: ${breakPoints.lg}px;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -${PADDING}px;
    margin-left: -${PADDING}px;
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: ${PADDING}px;
    padding-left: ${PADDING}px;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: ${breakPoints.md}px) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-md-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-md-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-md-2c {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-md-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-md-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-md-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media (min-width: ${breakPoints.lg}px) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-lg-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-lg-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-lg-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-lg-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-lg-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @font-face {
    font-family: 'Avenir Next';
    font-display: auto;
    font-weight: 700;
    src: local('Avenir Next Bold'),
      url(${AvenirNextBoldWOFF2}) format('woff2'),
      url(${AvenirNextBoldWOFF}) format('woff');
  }

  @font-face {
    font-family: 'Avenir Next';
    font-display: auto;
    font-weight: 500;
    src: local('Avenir Next Medium'),
      url(${AvenirNextMediumWOFF2}) format('woff2'),
      url(${AvenirNextMediumWOFF}) format('woff');
  }

  @font-face {
    font-family: 'Avenir Next';
    font-display: auto;
    font-weight: 400;
    src: local('Avenir Next Regular'),
      url(${AvenirNextRegularWOFF2}) format('woff2'),
      url(${AvenirNextRegularWOFF}) format('woff');
  }

  .hasPageSlide {
    @media (min-width: ${breakPoints.md}px) {
      scroll-snap-type: y mandatory;
      flex-direction: column;
      height: 100vh;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      width: 100%;
    }
  }
`;
