/**
 * Action type to set the open hamburger menu flag
 */
export const SET_OPEN_HAMBURGER_MENU = 'SET_OPEN_HAMBURGER_MENU';

/**
 * Action type to set the close hamburger menu flag
 */
export const SET_CLOSED_HAMBURGER_MENU = 'SET_CLOSED_HAMBURGER_MENU';

/**
 * Action type to set certain index as open
 */
export const ADD_SECTION_OPEN = 'ADD_SECTION_OPEN';

/**
 * Action type to set certain index as open
 */
export const REMOVE_SECTION_OPEN = 'REMOVE_SECTION_OPEN';

/**
 * Action type to set hasSlider flag
 */
export const SET_HAS_SLIDER = 'SET_HAS_SLIDER';

/**
 * Empty type for cases where you want to prompt out of a thunk
 * @type {string}
 */
export const EMPTY_TYPE = 'EMPTY_TYPE';

/**
 * Action type to set the active slide
 * @type {string}
 */
export const SET_ACTIVE_SLIDE = 'SET_ACTIVE_SLIDE';

/**
 * Action type to set slider ref
 * @type {string}
 */
export const SET_SLIDER_REF = 'SET_SLIDER_REF';

/**
 * Action type to remove the slider ref
 * @type {string}
 */
export const REMOVE_SLIDER_REF = 'REMOVE_SLIDER_REF';

/**
 * Action type to go to a specific slide
 * @type {string}
 */
export const GOTO_SLIDE = 'GOTO_SLIDE';

/**
 * Action type to set the slider animation status
 * @type {string}
 */
export const SET_SCROLL_ANIMATION_STATUS = 'SET_SCROLL_ANIMATION_STATUS';

/**
 * Action type to scroll to top
 * @type {string}
 */
export const SCROLL_TO_TOP = 'SCROLL_TO_TOP';
