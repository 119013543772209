import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { hasSliderSelector } from '../../../store/selectors';
import { isValidArray } from '../../../helpers';
import useFooterItems from './footerHook';
import AlestraLogoFooter from '../../base/AlestraLogoFooter';
import FooterSection from './FooterSection';
import Link from '../../base/Link';
import Styles from './Footer.styles';
import iconFactor from '../../../../static/images/icon-factor-black.png';

const LogoWrapper = styled.div`${Styles.logoWrapper}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

const FactorIdeaWrapper = styled.a`${Styles.factorIdeaWrapper}`;

/**
 * Footer
 * @returns {JSX}
 */
const Footer = () => {
  const navigation = useFooterItems();
  const hasSlider = useSelector(hasSliderSelector);
  const contactInfo = {
    type: 'contact',
  };

  const footerMenu = useMemo(
    () => isValidArray(navigation) && <FooterSection navigation={[contactInfo, ...navigation]} />,
    [navigation, contactInfo]
  );

  return (
    <Wrapper hasSlider={hasSlider}>
      <div className="container">
        <div className="row">
          <LogoWrapper className="col-12">
            <Link href="/" css={Styles.logo}>
              <AlestraLogoFooter />
            </Link>
          </LogoWrapper>
          {footerMenu}
        </div>
        <div className="row">
          <div className="col-12">
            <FactorIdeaWrapper href="http://factoridea.com.mx/" target="_blank" rel="noopener noreferrer">
              Desarrollado por
              <img src={iconFactor} alt="" />
            </FactorIdeaWrapper>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default React.memo(Footer);
