import get from 'lodash.get';
import React from 'react';
import axios from 'axios';

/**
 * Check if the code is executed in SSR
 * @returns {boolean}
 */
export function isSSR () {
  return typeof window === 'undefined';
}

/**
 * Helper function to determine if a given property exists
 * @param {Object} property Property being tested
 * @returns {boolean}
 */
export function exists(property) {
  if (typeof property !== 'undefined' && property) {
    return true;
  }
  return false;
}

/**
 * Helper that checks if the param is a valid function
 * @param {function} func - function to evaluate
 * @returns {boolean}
 */
export function isValidFunction(func) {
  return func && typeof func === 'function';
}

/**
 * Helper that checks if the param is a valid string
 * @param {string} str - string to evaluate
 * @returns {boolean}
 */
export function isValidString(str) {
  return (str && typeof str === 'string') || false;
}

/**
 * Helper to determine if array is valid and not valid
 * @param {array} array to be checked
 * @returns {boolean}
 */
export function isValidArray(array) {
  return exists(array) && Array.isArray(array) && array.length > 0;
}

/**
 * Helper that checks if the param is a valid object
 * @param {Object} obj - object to evaluate
 * @returns {boolean}
 */
export function isValidObject(obj) {
  return obj && typeof obj === 'object' && Object.keys(obj).length > 0;
}

/**
 * Helper function to get key if exist otherwise returns undefined
 * @param {Object} obj - The object to query
 * @param {Array|string} path - The path of the property to get
 * @param {*} [fallback = undefined] - fallback value
 * @param {bool} [strict = false] - if is set to true, it will accept null as valid
 * @returns {*}
 */
export function getKey(obj, path, fallback = undefined, strict = false) {
  if (!isValidObject(obj) || !exists(path)) return fallback;

  const result = obj[path] || get(obj, path, fallback);

  return strict || (!strict && result !== null) ? result : fallback;
}

/**
 * Validates if the number is a valid.
 * @param {number|string} value - value that needs to be validated
 * @returns {boolean} - if condition satisfy return true else returns false.
 */
export function isValidNumber(value) {
  if (typeof value === 'number') {
    return value - value === 0;
  }
  if (typeof value === 'string' && value.trim() !== '') {
    return Number.isFinite ? Number.isFinite(+value) : global.window.isFinite(+value);
  }
  return false;
}

/**
 * Converts a string to paragraph components
 * @param {string} text - to convert
 * @param {string} key - component key
 * @returns {JSX|boolean}
 */
export function convertParagraphs(text, key) {
  return isValidString(text)
  // eslint-disable-next-line react/no-array-index-key
  && text.split('\n\n').map((t, idx) => (<p key={`${key}-${idx}`}>{t}</p>));
}

const phoneCheck = /^[+#*\\[\]]*([0-9]*){6,45}$/;

const EMAIL_DOMAIN_BLACK_LIST = [
  'aol.com.mx',
  'bing.com',
  'gmail.com',
  'live.com',
  'live.com.mx',
  'lycos.com',
  'outlook.com',
  'prodigy.net',
  'redit.com',
  'telefonica.com',
  'telefonica.com.mx',
  'terra.com',
  'terra.com.mx',
  'yahoo.com',
  'yahoo.com.mx',
];

/**
 * Checks email domain is not part of the black list
 * @param {string} value - email value
 * @returns {boolean}
 */
export function emailIsInBlacklist(value) {
  if (!isValidString(value)) {
    return true;
  }

  const [, domain] = value.split('@');

  return EMAIL_DOMAIN_BLACK_LIST.includes(domain);
}

/**
 * Validate text inputs
 * @param {string} value - form value
 * @param {string} type - form type
 * @returns {boolean}
 */
export function validateInputs(value, type) {
  switch (type) {
    default:
    case 'text':
      return isValidString(value) && value.length >= 2;
    case 'email': {
      return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        && !emailIsInBlacklist(value);
    }
    case 'tel': {
      return phoneCheck.test(value) && value.length >= 8;
    }
  }
}

const mexicanStates = [
  'Aguascalientes',
  'Baja California Sur',
  'Baja California',
  'Campeche',
  'Chiapas',
  'Chihuahua',
  'Ciudad de México',
  'Coahuila',
  'Colima',
  'Durango',
  'Estado de México',
  'Guanajuato',
  'Guerrero',
  'Hidalgo',
  'Jalisco',
  'Michoacán',
  'Morelos',
  'Nayarit',
  'Nuevo León',
  'Oaxaca',
  'Puebla',
  'Querétaro',
  'Quintana Roo',
  'San Luis Potosí',
  'Sinaloa',
  'Sonora',
  'Tabasco',
  'Tamaulipas',
  'Tlaxcala',
  'Veracruz',
  'Yucatán',
  'Zacatecas',
];

/**
 * returns mexican states
 * @returns {Array}
 */
export function getMexicanStates() {
  const states = mexicanStates.map(state => ({
    name: state,
    value: state,
  }));

  return [
    {
      name: 'Estado',
      value: '',
    },
    ...states,
  ];
}

/**
 * strip html tags helper
 * @param {string} html - html to clean
 * @returns {string}
 */
export function stripHtml(html) {
  return html.replace(/<[^>]*>?/gm, '');
}

/**
 * Capitalise first letter of string ('hello' -> 'Hello')
 * @param {string} str to transform
 * @returns {string}
 */
export function capFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Check if provided url is absolute
 * @param {string} url to test
 * @returns {bool}
 */
export function isAbsoluteUrl(url) {
  if (typeof url !== 'string') {
    return false;
  }

  // Don't match Windows paths `c:\`
  if (/^[a-zA-Z]:\\/.test(url)) {
    return false;
  }

  // Scheme: https://tools.ietf.org/html/rfc3986#section-3.1
  // Absolute URL: https://tools.ietf.org/html/rfc3986#section-4.3
  return /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(url);
}

/**
 * Retrieves remote IP from web service
 * @param {function} callback - callback once the request is done
 */
export async function getRemoteAddress(callback) {
  try {
    const response = await axios.get('https://api.alestradev.com/webhook/remote');
    const { data: { ipAddresses } } = response;
    localStorage.setItem('remoteAddress', ipAddresses);
    callback(ipAddresses);
  } catch (e) {
    callback(null);
  }
}
