import React, { useLayoutEffect, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion, useMotionValue, useSpring } from 'framer-motion';
import styled from 'styled-components';

// import { useBreakPoint } from '../../helpers/hooks';
import Header from '../compound/Header';
import Footer from '../compound/Footer';
import GlobalStyles from './GlobalStyles';

const MainScroll = styled(motion.div)`display: block;`; // prettier-ignore

/**
 * Layout Component
 * @param {Object} props - component props
 * @param {Array|element|function|Node} props.children - component childs
 * @param {boolean} props.hasSlider true if it has slider
 * @param {func} props.removeSliderRef - dispatch action to remove ref
 * @param {func} props.scrollAnimationEnd - scrollAnimation end position
 * @param {func} props.scrollAnimationStart - scrollAnimation start position
 * @param {func} props.scrollAnimationStatus - scrollAnimation status
 * @param {bool} props.setScrollAnimationStatus - dispatch action to stop the animation
 * @param {func} props.setSliderRef - dispatch action to add ref
 * @returns {JSX}
 */
const Layout = ({
  children,
  hasSlider,
  removeSliderRef,
  scrollAnimationEnd,
  scrollAnimationStart,
  scrollAnimationStatus,
  setScrollAnimationStatus,
  setSliderRef,
}) => {
  const ref = useRef();
  const motionValue = useMotionValue(scrollAnimationStart);
  const spring = useSpring(motionValue, {
    damping: 30, restDelta: 0.5, restSpeed: 0.5, stiffness: 230
  });

  // useLayoutEffect(() => {
  //   // if (hasSlider && breakPoint !== 'sm') {
  //   //   // document.body.style.overflow = 'hidden';
  //   // } else {
  //   //   // document.body.style.overflow = 'auto';
  //   // }
  //   // // document.body.style.overflow = 'hidden';

  //   if (ref.current) {
  //     ref.current.scrollTop = 0;
  //   }
  // }, [breakPoint, hasSlider]);

  useEffect(() => {
    if (ref.current) {
      setSliderRef(ref);
    }

    return () => {
      removeSliderRef();
    };
  }, [setSliderRef, removeSliderRef]);

  useLayoutEffect(() => {
    /**
     * updateScroll function
     * @param {number} value - current scroll position
     */
    function updateScoll(value) {
      const shouldAnimate = Math.abs(scrollAnimationEnd - value) > 1;

      if (shouldAnimate) {
        ref.current.style.scrollSnapType = 'none';
        ref.current.scrollTop = value;
      } else {
        ref.current.scrollTop = scrollAnimationEnd;
        ref.current.style.scrollSnapType = hasSlider ? 'y mandatory' : 'none';
        setScrollAnimationStatus(false);
        spring.stop();
        motionValue.stop();
      }
    }

    if (scrollAnimationStatus) {
      motionValue.current = scrollAnimationStart;
      spring.current = scrollAnimationStart;
      motionValue.set(scrollAnimationEnd);
    }

    const unsubscribeScroll = spring.onChange(updateScoll);

    return () => {
      unsubscribeScroll();
    };
  }, [
    scrollAnimationEnd,
    spring,
    hasSlider,
    scrollAnimationStart,
    motionValue,
    setScrollAnimationStatus,
    scrollAnimationStatus,
  ]);

  return (
    <>
      <GlobalStyles />
      <MainScroll
        className="hasPageSlide"
        ref={ref}
      >
        <Header />
        <div style={{ minHeight: '100vh' }}>
          {children}
        </div>
        <Footer />
      </MainScroll>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
  hasSlider: PropTypes.bool,
  removeSliderRef: PropTypes.func,
  scrollAnimationEnd: PropTypes.number,
  scrollAnimationStart: PropTypes.number,
  scrollAnimationStatus: PropTypes.bool,
  setScrollAnimationStatus: PropTypes.func,
  setSliderRef: PropTypes.func,
};

export default Layout;
