export default {
  isClosed: {
    height: 0,
    transition: {
      damping: 35,
      stiffness: 400,
      type: 'spring',
    },
  },
  isOpen: {
    height: '100%',
    transition: {
      damping: 29,
      stiffness: 400,
      type: 'spring',
    },
  },
};
