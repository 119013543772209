import {
  ADD_SECTION_OPEN,
  GOTO_SLIDE,
  REMOVE_SECTION_OPEN,
  REMOVE_SLIDER_REF,
  SCROLL_TO_TOP,
  SET_ACTIVE_SLIDE,
  SET_CLOSED_HAMBURGER_MENU,
  SET_HAS_SLIDER,
  SET_OPEN_HAMBURGER_MENU,
  SET_SCROLL_ANIMATION_STATUS,
  SET_SLIDER_REF,
} from './types';
import {
  activeSlideSelector,
  hasSliderSelector,
  isHamburgerOpenSelector,
  scrollAnimationStatusSelector,
  sliderRefSelector,
} from '../selectors';
import { getKey } from '../../helpers';

/**
 * Sets hamburger open
 * @returns {Object}
 */
export function setHamburgerOpen() {
  return { type: SET_OPEN_HAMBURGER_MENU };
}

/**
 * Sets has slider
 * @param {boolean} value - if true, it has page slider
 * @returns {Object}
 */
export function setHasSlider(value) {
  return (dispatch, getState) => {
    const hasSlider = hasSliderSelector(getState());

    if (hasSlider !== value) {
      dispatch({ type: SET_HAS_SLIDER, value });
    }

    return null;
  };
}

/**
 * Sets hamburger open
 * @returns {Object}
 */
export function setHamburgerClose() {
  return (dispatch, getState) => {
    const isHamburgerOpen = isHamburgerOpenSelector(getState());

    if (isHamburgerOpen) {
      dispatch({ type: SET_CLOSED_HAMBURGER_MENU });
    }

    return null;
  };
}

/**
 * Adds a new section to sectionsOpen
 * @param {number} section - section to add
 * @returns {Object}
 */
export function addSectionOpen(section) {
  return { section, type: ADD_SECTION_OPEN };
}

/**
 * Removes a new section from sectionsOpen
 * @param {number} section - section to remove
 * @returns {Object}
 */
export function removeSectionOpen(section) {
  return { section, type: REMOVE_SECTION_OPEN };
}

/**
 * Sets the active slide
 * @param {number} slide - active slide
 * @returns {Object}
 */
export function setActiveSlide(slide) {
  return (dispatch, getState) => {
    const activeSlide = activeSlideSelector(getState());

    if (activeSlide !== slide) {
      dispatch({ slide, type: SET_ACTIVE_SLIDE });
    }

    return null;
  };
}

/**
 * Sets the slider reference
 * @param {number} slider - slider ref
 * @returns {Object}
 */
export function setSliderRef(slider) {
  return { slider, type: SET_SLIDER_REF };
}

/**
 * Sets the slider reference
 * @returns {Object}
 */
export function removeSliderRef() {
  return { type: REMOVE_SLIDER_REF };
}

/**
 * Sets the slider reference
 * @param {number} slide - slide to go
 * @returns {Object}
 */
export function gotoSlide(slide) {
  return { slide, type: GOTO_SLIDE };
}

/**
 * Sets the scoll animation status
 * @param {number} value - animation status
 * @returns {Object}
 */
export function setScrollAnimationStatus(value) {
  return (dispatch, getState) => {
    const scrollAnimationStatus = scrollAnimationStatusSelector(getState());

    if (value !== scrollAnimationStatus) {
      dispatch({ type: SET_SCROLL_ANIMATION_STATUS, value });
    }

    return null;
  };
}

/**
 * Scroll to top action
 * @returns {Object}
 */
export function scrollToTop() {
  return (dispatch, getState) => {
    const sliderRef = sliderRefSelector(getState());
    const sliderRefCurrent = getKey(sliderRef, 'current');

    if (sliderRefCurrent) {
      sliderRefCurrent.scrollTop = 0;
      dispatch({ type: SCROLL_TO_TOP });
    }

    return null;
  };
}
