import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { useHeight } from '../../../../helpers/hooks';
import useHamburgerItems from './hamburgerMenuHook';
import Items from './Items';
import Animation from './HamburgerMenu.animation';
import HamburgerIconAnimation from './HamburgerIcon.animation';
import Styles from './HamburgerMenu.styles';
import { isValidArray } from '../../../../helpers';

const Button = styled.button`${Styles.button}`; // prettier-ignore
const CircleAnimation = styled(motion.div)`${Styles.circleAnimation}`; // prettier-ignore
const Mask = styled(motion.div)`${Styles.mask}`; // prettier-ignore
const Nav = styled(motion.nav)`${Styles.nav}`; // prettier-ignore
const Path = styled(motion.path)`${Styles.path}`; // prettier-ignore
const ScrollWrapper = styled.div`${Styles.scrollWrapper}`; // prettier-ignore
const Svg = styled(motion.svg)`${Styles.svg}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

/**
 * HamburgerIcon component
 * @param {Object} props - component props
 * @param {string} [props.className] - modifer class
 * @param {Object} [props.isHamburgerOpen] - if true, the hamburger is open
 * @param {function} props.setHamburgerClose - function to close the hamburger
 * @param {function} props.setHamburgerOpen - function to open the hamburger
 * @returns {JSX}
 */
const HamburgerMenu = ({
  className,
  isHamburgerOpen,
  sectionsOpen,
  setHamburgerClose,
  setHamburgerOpen,
}) => {
  const heightValue = useHeight();
  const menu = useHamburgerItems();
  const mainMenu = useMemo(() => isValidArray(menu) && <Items menu={menu} />, [
    menu,
  ]);

  return (
    <Wrapper
      className={className}
      heightValue={isHamburgerOpen ? heightValue : heightValue - 1}
    >
      <Button
        onClick={isHamburgerOpen ? setHamburgerClose : setHamburgerOpen}
        type="button"
      >
        <Svg
          animate={isHamburgerOpen ? 'open' : 'closed'}
          viewBox="0 0 30 30"
          variants={HamburgerIconAnimation}
          custom="svg"
          initial={false}
        >
          <Path isHamburgerOpen={isHamburgerOpen} variants={HamburgerIconAnimation} custom="bar1" />
          <Path isHamburgerOpen={isHamburgerOpen} variants={HamburgerIconAnimation} custom="bar2" />
          <Path isHamburgerOpen={isHamburgerOpen} variants={HamburgerIconAnimation} custom="bar3" />
        </Svg>
      </Button>
      <Mask
        animate={isHamburgerOpen ? 'open' : 'closed'}
        variants={Animation}
        initial={false}
        custom={{ element: 'mask', heightValue }}
      >
        <CircleAnimation
          variants={Animation}
          custom={{ element: 'circleAnimation', heightValue }}
          initial={false}
        />
        <Nav
          itemScope
          itemType="http://www.schema.org/SiteNavigationElement"
          variants={Animation}
          custom={{ element: 'navigation' }}
          role="navigation"
          isHamburgerOpen={isHamburgerOpen}
          initial={false}
        >
          <ScrollWrapper isVisible={sectionsOpen.length >= 1 && isHamburgerOpen} />
          {mainMenu}
        </Nav>
      </Mask>
    </Wrapper>
  );
};

HamburgerMenu.propTypes = {
  className: PropTypes.string,
  isHamburgerOpen: PropTypes.bool,
  sectionsOpen: PropTypes.array,
  setHamburgerClose: PropTypes.func,
  setHamburgerOpen: PropTypes.func,
};

export default memo(HamburgerMenu);
