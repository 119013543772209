import { useStaticQuery, graphql } from 'gatsby';
import { isValidArray, getKey } from '../../../../helpers';

/**
 * useHamburgerItems hooks
 * @returns {Object}
 */
export default function useHamburgerItems() {
  const hamburgerItems = useStaticQuery(
    graphql`
      query {
        data: allStrapiHamburgeritems(
          sort: {
            fields: order
        }) {
          navigation: nodes {
            mainSection: mainItem {
              id,
              name,
              page {
                id,
                uri: slug
              },
            }
            childs: childItems {
              id,
              externalLink,
              order,
              page {
                id,
                uri: slug,
              },
              name,
            }
          }
        }
      }
    `
  );

  const navigation = getKey(hamburgerItems, 'data.navigation');

  return isValidArray(navigation)
    ? navigation.map(item => ({
      ...item.mainSection,
      childs: item.childs
    }))
    : {};
}
