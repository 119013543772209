import {
  ADD_SECTION_OPEN,
  GOTO_SLIDE,
  REMOVE_SECTION_OPEN,
  REMOVE_SLIDER_REF,
  SCROLL_TO_TOP,
  SET_ACTIVE_SLIDE,
  SET_CLOSED_HAMBURGER_MENU,
  SET_HAS_SLIDER,
  SET_OPEN_HAMBURGER_MENU,
  SET_SCROLL_ANIMATION_STATUS,
  SET_SLIDER_REF,
} from '../actions/types';

const initialState = {
  activeSlide: 0,
  hasSlider: false,
  isHamburgerOpen: false,
  scrollAnimationEnd: 0,
  scrollAnimationStart: 0,
  scrollAnimationStatus: false,
  sectionsOpen: [],
  sliderRef: null,
};

/**
 * Redux Reducer
 * @param {Object} state - store state
 * @param {string} action - redux action
 * @returns {Object}
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_OPEN_HAMBURGER_MENU:
      return {
        ...state,
        isHamburgerOpen: true,
      };
    case SET_HAS_SLIDER:
      return {
        ...state,
        hasSlider: action.value,
      };
    case SET_ACTIVE_SLIDE:
      return {
        ...state,
        activeSlide: action.slide,
      };
    case SET_SLIDER_REF:
      return {
        ...state,
        sliderRef: action.slider,
      };
    case REMOVE_SLIDER_REF:
      return {
        ...state,
        sliderRef: null,
      };
    case GOTO_SLIDE:
      if (state.sliderRef) {
        const { sliderRef } = state;
        const scrollAnimationEnd = sliderRef.current.offsetHeight * action.slide;
        const currentPosition = sliderRef.current.scrollTop;

        return {
          ...state,
          scrollAnimationEnd,
          scrollAnimationStart: currentPosition,
          scrollAnimationStatus: currentPosition !== scrollAnimationEnd
        };
      }

      return state;
    case SET_CLOSED_HAMBURGER_MENU:
      return {
        ...state,
        isHamburgerOpen: false,
        sectionsOpen: [],
      };
    case ADD_SECTION_OPEN:
      return {
        ...state,
        sectionsOpen: [...new Set([...state.sectionsOpen, action.section])],
      };
    case REMOVE_SECTION_OPEN:
      return {
        ...state,
        sectionsOpen: state.sectionsOpen.filter(item => item !== action.section),
      };
    case SET_SCROLL_ANIMATION_STATUS:
      return {
        ...state,
        scrollAnimationStatus: action.value,
      };
    case SCROLL_TO_TOP:
      return {
        ...initialState,
        sliderRef: state.sliderRef,
      };
    default:
      return state;
  }
}
