import { connect } from 'react-redux';

import { sectionsOpen } from '../../../../../store/selectors';
import { addSectionOpen, removeSectionOpen } from '../../../../../store/actions';
import Items from './Items';

/**
 * map State to props
 * @param {Object} state - store state
 * @returns {Object}
 */
const mapStateToProps = state => ({
  sectionsOpen: sectionsOpen(state),
});

const mapDispatchToProps = {
  addSectionOpen,
  removeSectionOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Items);
