import { css } from 'styled-components';

import { MID_GRAY } from '../../../../../constants/colors';

export default {
  path: css`
  fill: transparent;
  stroke: ${MID_GRAY};
  stroke-linecap: round;
  stroke-width: 2;
`,
  svg: css`
    height: 25px;
    width: 25px;
  `,
};
