import { connect } from 'react-redux';

import { isHamburgerOpenSelector, sectionsOpen } from '../../../../store/selectors';
import { setHamburgerClose, setHamburgerOpen } from '../../../../store/actions';
import HamburgerMenu from './HamburgerMenu';

/**
 * map State to props
 * @param {Object} state - store state
 * @returns {Object}
 */
const mapStateToProps = state => ({
  isHamburgerOpen: isHamburgerOpenSelector(state),
  sectionsOpen: sectionsOpen(state),
});

const mapDispatchToProps = {
  setHamburgerClose,
  setHamburgerOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HamburgerMenu);
