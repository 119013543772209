import { css } from 'styled-components';

import { media, rem } from '../../../helpers/mixins';
import { WHITE, MID_GRAY } from '../../../constants/colors';

export default {
  factorIdeaWrapper: css`
    color: ${MID_GRAY};
    font-size: ${rem(12)};

    &:hover {
      color: ${MID_GRAY};
    }

    img {
      height: 15px;
      margin-left: 7px;
    }
  `,
  logoWrapper: css`
    margin-bottom: 32px;
  `,
  wrapper: ({ hasSlider }) => css`
    background-color: ${WHITE};
    padding: 32px 0;
    position: relative;
    z-index: 4;

    ${hasSlider && media.md`
      scroll-snap-align: start;
    `}
  `,
};
