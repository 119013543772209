import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Styles from './Link.styles';

const LinkStyled = styled(Link)`${Styles.linkStyled}`; // prettier-ignore

/**
 * Link Component
 * @param {Object} props - component props
 * @param {string} props.href - href prop
 * @returns {JSX}
 */
const LinkComponent = props => {
  const {
    children,
    href,
    onClick,
    preloadDisabled,
    target,
    ...otherProps
  } = props;

  if (preloadDisabled) {
    return <a href={href} target={target} {...otherProps}>{children}</a>;
  }

  return (
    <LinkStyled to={href} onClick={onClick} {...otherProps}>
      {children}
    </LinkStyled>
  );
};

LinkComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
  href: PropTypes.string,
  onClick: PropTypes.func,
  preloadDisabled: PropTypes.bool,
  target: PropTypes.string,
};

LinkComponent.defaultProps = {
  target: '_self',
};

export default LinkComponent;
