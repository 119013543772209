import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';

/**
 * StoreManager state.
 */
class State {
  /**
   * Constructor
   */
  constructor() {
    const middlewares = [thunk];

    if (process.env.NODE_ENV === 'development') {
      const { logger } = require('redux-logger'); // eslint-disable-line global-require
      middlewares.push(logger);
    }

    this.Store = createStore(reducers, applyMiddleware(...middlewares));
  }

  /**
   * Return the redux store
   * @returns {*}
   * @constructor
   */
  getStore() {
    return this.Store;
  }
}

export default State;
