import { createSelector } from 'reselect';

/**
 * currentState
 * @param {Object} state - Store state
 * @returns {Object}
 */
const currentState = state => state;

/**
 * isHamburgerOpen selector
 * @param {Object} state - Store state
 * @returns {boolean}
 */
export const isHamburgerOpenSelector = createSelector(
  currentState,
  state => state.isHamburgerOpen
);


/**
 * sectionOpen selector
 * @param {Object} state -Store state
 * @returns {boolean}
 */
export const sectionsOpen = createSelector(
  currentState,
  state => state.sectionsOpen
);

/**
 * hasSlider selector
 * @param {Object} state -Store state
 * @returns {boolean}
 */
export const hasSliderSelector = createSelector(
  currentState,
  state => state.hasSlider
);

/**
 * activeSlide selector
 * @param {Object} state -Store state
 * @returns {boolean}
 */
export const activeSlideSelector = createSelector(
  currentState,
  state => state.activeSlide
);

/**
 * scrollAnimationStartSelector selector
 * @param {Object} state -Store state
 * @returns {boolean}
 */
export const scrollAnimationStartSelector = createSelector(
  currentState,
  state => state.scrollAnimationStart
);

/**
 * scrollAnimationEnd selector
 * @param {Object} state -Store state
 * @returns {boolean}
 */
export const scrollAnimationEndSelector = createSelector(
  currentState,
  state => state.scrollAnimationEnd
);

/**
 * scrollAnimationStatus selector
 * @param {Object} state -Store state
 * @returns {boolean}
 */
export const scrollAnimationStatusSelector = createSelector(
  currentState,
  state => state.scrollAnimationStatus
);

/**
 * sliderRef selector
 * @param {Object} state -Store state
 * @returns {boolean}
 */
export const sliderRefSelector = createSelector(
  currentState,
  state => state.sliderRef
);
