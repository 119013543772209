import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Styles from './Arrow.styles';
import Animation from './Arrow.animation';

const Path = styled(motion.path)`${Styles.path}`; // prettier-ignore
const Svg = styled(motion.svg)`${Styles.svg}`; // prettier-ignore

/**
 * Arrow Component
 * @param {props} props - component props
 * @param {boolean} props.isOpen - if true, it will render the arrow down
 * @returns {JSX}
 */
const Arrow = ({ isOpen }) => (
  <Svg viewBox="0 0 20 20">
    <Path variants={Animation} animate={isOpen ? 'isOpen' : 'isClosed'} initial={false} />
  </Svg>
);

Arrow.propTypes = {
  isOpen: PropTypes.bool,
};

export default Arrow;
