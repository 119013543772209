import State from './State';

/**
 * Manager for the redux store.
 */
class StoreManager {
  /**
   * Create a fresh, new state (including a fresh, new store)
   * @returns {Object} StoreManager state
   */
  init() {
    this.state = new State();

    return this.state;
  }

  /**
   * Return the redux store
   * @returns {*}
   * @constructor
   */
  getStore() {
    if (!this.state) {
      this.init();
    }

    return this.state.getStore();
  }
}

export default new StoreManager();
