import { css } from 'styled-components';

export default {
  linkStyled: css`
    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &:not([href]):not([tabindex]) {
      color: inherit;
      text-decoration: none;
    }

    &:not([href]):not([tabindex]):hover,
    a:not([href]):not([tabindex]):focus {
      color: inherit;
      text-decoration: none;
    }

    &:not([href]):not([tabindex]):focus {
      outline: 0;
    }
  `,
};
