import {
  useEffect, useReducer, useRef, useState,
} from 'react';
import debounce from 'lodash.debounce';

import breakPoints from '../../constants/breakPoints';
import { isSSR } from '..';

/**
 * useHeight custom hook
 * @example
 * const height = useHeight()
 * @returns {number}
 */
export function useHeight() {
  const [height, setHeight] = useState(isSSR()
    ? 100 : window.innerHeight);

  useEffect(() => {
    let didCancel = false;
    const handleResize = debounce(() => {
      if (!didCancel) setHeight(window.innerHeight);
    });
    window.addEventListener('resize', handleResize);

    return () => {
      didCancel = true;
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return height;
}

/**
 * getBreakpoint
 * @param {number} width - screen width
 * @returns {string}
 */
function getBreakpoint(width) {
  let breakpoint;

  // We're using if instead of switch based on browsers performance tests
  // http://jsfiddle.net/some/HKdug/
  if (width >= breakPoints.md && width < breakPoints.lg) {
    breakpoint = 'md';
  } else if (width >= breakPoints.lg) {
    breakpoint = 'lg';
  } else {
    breakpoint = 'sm';
  }

  return breakpoint;
}

/**
 * useBreakPoint custom hook
 * @example
 * const breakpoint = useBreakPoint();
 * @returns {string}
 */
export function useBreakPoint() {
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const breakPoint = useRef(getBreakpoint(isSSR() ? 0 : window.innerWidth));

  useEffect(() => {
    let didCancel = false;
    const handleResize = debounce(() => {
      const currentBreakPoint = getBreakpoint(window.innerWidth);

      if (currentBreakPoint !== breakPoint.current && !didCancel) {
        breakPoint.current = currentBreakPoint;
        forceUpdate();
      }
    }, 100);
    window.addEventListener('resize', handleResize);

    return () => {
      didCancel = true;
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return breakPoint.current;
}

/**
 * useIsMounted hook
 * @returns {boolean}
 */
export const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => false;
  }, []);

  return isMounted.current;
};

/**
 * usePrevious Hook
 * @param {any} value - value to save
 * @returns {any}
 */
export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}


/**
 * useIsInView hook
 * @param {string} options - intersection options
 * @returns {boolean}
 */
export const useIsInView = options => {
  const {
    margin = 0,
    percentage = 0.55,
  } = options || {};
  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const { current: element } = ref;

    const observer = new IntersectionObserver(([entry]) => {
      const isIntersectingValue = entry.intersectionRatio >= percentage;
      if (isIntersecting !== isIntersectingValue) {
        setIntersecting(isIntersectingValue);
      }
    }, { rootMargin: `${margin}px`, threshold: [0, 0.25, 0.45, 0.5, 0.75, 1] });

    if (element) observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [margin, percentage, isIntersecting]);

  return [ref, isIntersecting];
};

/**
 * Re-usable and declarative setInterval hook. Created by Dan Abramov.
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param {Function} callback the function to execute on every interval.
 * @param {number | null} interval the duration of the interval in milliseconds
 * or null to cancel the interval.
 */
export function useInterval(callback, interval) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    /**
     * Handler that gets executed on every interval
     */
    function tick() {
      savedCallback.current();
    }

    if (interval !== null) {
      const id = setInterval(tick, interval);
      return () => clearInterval(id);
    }
  }, [interval]);
}
