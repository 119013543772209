import { css } from 'styled-components';

import { MID_GRAY } from '../../../../../constants/colors';

export default {
  subSectionItem: css`
    color: ${MID_GRAY};
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
  `,
};
